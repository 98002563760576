import React from 'react';
import '../App.css';

const LotteryCard = ({ isActive, entryAmount, tokensNeeded, lotteryId, totalBurned }) => {
    return (
        <div className="card">
            <div className={`status ${isActive ? 'active' : 'inactive'}`}>
                {isActive ? `Lottery #${lotteryId} is active` : `Lottery #${lotteryId} is finished`}
            </div>
            <div className="total-burned">
                Total Burned: {totalBurned} $CRASH
            </div>
            {isActive &&
                <div className="entry-amount">
                    Entry Amount: {entryAmount} $CRASH
                </div>}
            {isActive && <div className="tokens-needed">
                Draw on: {tokensNeeded} $CRASH
            </div>
            }
        </div>
    );
};

export default LotteryCard;