import React from 'react';
import '../App.css';
import { formatUnits } from 'ethers';

const WinnersCard = ({ winners, lotteryId }) => {
    return (
        <div className="card">
            <h2>Lottery #{parseInt(lotteryId) > 0 ? parseInt(lotteryId) - 1 : 0} Winners</h2>
            {winners.length > 0 ? (
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {winners.map((winner, index) => (
                        <li key={index} style={{ fontSize: '0.8em' }}>
                            Winner {index + 1} ({formatUnits(winner.winnings, 9)} $CRASH) <br></br> {winner.address}
                        </li>
                    ))}
                    <br></br>
                    <li style={{ fontSize: '0.8em' }}>
                        Burned: 20%
                    </li>
                </ul>
            ) : (
                <p>No winners yet</p>
            )}
        </div>
    );
};

export default WinnersCard;
