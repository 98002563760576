import "./App.css";
import Header from "./components/Header";
import LotteryCard from "./components/LotteryCard";
import UserCard from "./components/UserCard";
import ParticipantsCard from "./components/ParticipantsCard";
import WinnersCard from "./components/WinnersCard";
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react';
import { useSwitchNetwork } from '@web3modal/ethers/react';
import { BrowserProvider, Contract, formatUnits, parseUnits } from 'ethers';
import React, { useState, useEffect, useCallback } from 'react';

const LotteryAddress = '0xe35449A28679233bdb2DE1f45e754D0dc1501cbA';
const LotteryABI = require('./abi/Lottery.json').abi;

const tokenAddress = '0x9D6dB6382444b70a51307A4291188f60D4EEF205';
const tokenABI = require('./abi/IBEP20.json').abi;

function App() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { switchNetwork } = useSwitchNetwork();
  const { walletProvider } = useWeb3ModalProvider();

  const [isActive, setIsActive] = useState(null);
  const [entryAmount, setEntryAmount] = useState(null);
  const [tokensNeeded, setTokensNeeded] = useState(null);
  const [lotteryId, setLotteryId] = useState(null);
  const [allowance, setAllowance] = useState(null);
  const [entries, setEntries] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [winners, setWinners] = useState([]);
  const [totalBurned, setTotalBurned] = useState(null);
  const [refresh, setRefresh] = useState(false);

  // update if user changes account or network

  const fetchData = useCallback(async (signer) => {
    setRefresh(false);
    const LotteryContract = new Contract(LotteryAddress, LotteryABI, signer);
    const TokenContract = new Contract(tokenAddress, tokenABI, signer);

    const [lotteryActive, entryAmt, tokens, lotteryId, totalBurned] = await Promise.all([
      LotteryContract.lotteryActive(),
      LotteryContract.entryAmount(),
      LotteryContract.minimumTokens(),
      LotteryContract.lottery_id(),
      LotteryContract.totalBurned()
    ]);

    setIsActive(lotteryActive);
    setEntryAmount(formatUnits(entryAmt, 9).toString());
    setTokensNeeded(formatUnits(tokens, 9).toString());
    setLotteryId(lotteryId.toString());

    const signerEntries = await LotteryContract.lotteryEntries(lotteryId.toString(), address);
    setEntries(signerEntries.toString());

    const signerAllowance = await TokenContract.allowance(address, LotteryAddress);
    setAllowance(formatUnits(signerAllowance, 9).toString());

    const participantsList = await LotteryContract.getParticipants();
    setParticipants(participantsList);
    const winner0 = await LotteryContract.winners(0);
    const winner1 = await LotteryContract.winners(1);
    const winner2 = await LotteryContract.winners(2);
    const zeroAddress = '0x' + '0'.repeat(40);
    let winnersList = [];
    let winner0winnings, winner1winnings, winner2winnings;
    winner0winnings = "0";
    winner1winnings = "0";
    winner2winnings = "0";
    if (winner0 !== zeroAddress && winner1 !== zeroAddress && winner2 !== zeroAddress) {
      winnersList = [winner0, winner1, winner2];
      winner0winnings = await LotteryContract.lotteryWinners(lotteryId, winner0);
      winner1winnings = await LotteryContract.lotteryWinners(lotteryId, winner1);
      winner2winnings = await LotteryContract.lotteryWinners(lotteryId, winner2);
    }
    setWinners(winnersList.map((winner, index) => ({
      address: winner,
      winnings: [winner0winnings, winner1winnings, winner2winnings][index]
    })));
    setTotalBurned(formatUnits(totalBurned, 9).toString());
  }, [address]);

  useEffect(() => {
    if (chainId !== 56) {
      switchNetwork(56);
    }
  }, [chainId, switchNetwork]);

  useEffect(() => {
    const setupProvider = async () => {
      if (isConnected) {
        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        fetchData(signer);

        // Event listener for account change
        if (window.ethereum && window.ethereum.on) {
          const handleAccountsChanged = (accounts) => {
            if (accounts.length > 0) {
              fetchData(ethersProvider.getSigner());
            }
          };
          window.ethereum.on('accountsChanged', handleAccountsChanged);

          // Clean up event listener on unmount
          return () => {
            window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
          };
        }
      }
    };

    setupProvider();
  }, [isConnected, walletProvider, fetchData, isActive, refresh]);

  async function handleApprove() {
    if (isConnected) {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const TokenContract = new Contract(tokenAddress, tokenABI, signer);
      const tx = await TokenContract.approve(LotteryAddress, parseUnits(entryAmount, 18));
      await tx.wait();
      const newAllowance = await TokenContract.allowance(address, LotteryAddress);
      setAllowance(formatUnits(newAllowance, 9).toString());
    }
  }

  async function handleParticipate(numberOfEntries) {
    if (isConnected) {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const LotteryContract = new Contract(LotteryAddress, LotteryABI, signer);
      const tx = await LotteryContract.participate(parseInt(numberOfEntries), { gasLimit: 500000 });
      await tx.wait();
      setRefresh(true);
      // const newEntries = await LotteryContract.lotteryEntries(lotteryId.toString(), address);
      // setEntries(newEntries.toString());

      // const participantsList = await LotteryContract.getParticipants();
      // setParticipants(participantsList);
    }
  }

  if (!isConnected) {
    return <div className="App"><Header /><p>Please connect your wallet</p></div>;
  }

  return (
    <div className="App">
      <Header />
      {isActive != null && entryAmount !== null && tokensNeeded !== null &&
        <LotteryCard isActive={isActive} entryAmount={entryAmount} tokensNeeded={tokensNeeded} lotteryId={lotteryId} totalBurned={totalBurned} />
      }
      {isActive && allowance !== null && entries !== null &&
        <UserCard
          allowance={allowance}
          entries={entries}
          entryAmount={entryAmount}
          handleApprove={handleApprove}
          handleParticipate={handleParticipate}
        />
      }
      <WinnersCard winners={winners} lotteryId={lotteryId} />
      {isActive && <ParticipantsCard participants={participants} />}

    </div>
  );
}

export default App;
