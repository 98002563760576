import React from 'react';
import '../App.css';

const ParticipantsCard = ({ participants }) => {
    return (
        <div className="card">
            <h2>Participants</h2>
            {participants.length > 0 ? (
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {participants.map((participant, index) => (
                        <li key={index} style={{ fontSize: '0.8em' }}>{participant}</li>
                    ))}
                </ul>
            ) : (
                <p>No participants yet</p>
            )}
        </div>
    );
};

export default ParticipantsCard;