import React, { useState } from 'react';
import '../App.css';

const UserCard = ({ allowance, entries, entryAmount, handleApprove, handleParticipate }) => {
    const [numberOfEntries, setNumberOfEntries] = useState(1);

    const handleIncrement = () => {
        setNumberOfEntries(prevEntries => prevEntries + 1);
    };

    const handleDecrement = () => {
        setNumberOfEntries(prevEntries => Math.max(1, prevEntries - 1));
    };
    return (
        <div className="card">
            <div className="entry-amount">
                Your entries: {entries}
            </div>
            Number of entries:
            <div className="entry-controls">
                <button className="entry-button" onClick={handleDecrement}>-</button>
                <span className="entry-count">{numberOfEntries}</span>
                <button className="entry-button" onClick={handleIncrement}>+</button>
            </div>
            {allowance > entryAmount * numberOfEntries ? (
                <div className="Button" onClick={() => handleParticipate(numberOfEntries)}>
                    Participate
                </div>
            ) : (
                <div className="Button" onClick={handleApprove}>
                    Approve
                </div>
            )}
        </div>
    );
};

export default UserCard;