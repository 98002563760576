import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'


// 1. Get projectId
const projectId = '21f0a02703b78b519cbdf1ed643123ea'

// 2. Set chains
const bsc = {
  chainId: 56,
  name: 'BSC',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com/',
  rpcUrl: 'https://bsc-dataseed2.binance.org/'
}

// 3. Create a metadata object
const metadata = {
  name: 'CrashCoin Lottery',
  description: 'CrashCoin Lottery',
  url: 'https://lottery.crashcoin.fun', // origin must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  // enableEIP6963: true, // true by default
  // enableInjected: true, // true by default
  // enableCoinbase: true, // true by default
  rpcUrl: 'https://bsc-dataseed2.binance.org/', // used for the Coinbase SDK
  defaultChainId: 56 // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [bsc],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  allowUnsupportedChain: false
})

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
