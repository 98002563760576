import React from 'react'
import Logo from '../logo.jpg'

function Header(props) {
  return (
    <header>
      <div className='leftH'>
        <img src={Logo} alt='eth' className='logo' />
        Lottery
        {/* <Link to='/' className='link'>
          <div className='headerItem'>Swap</div>
        </Link> */}
      </div>
      <div className='rightH'>
        <w3m-button />
      </div>
    </header>
  )
}

export default Header